import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, SubContainer } from '../../../components/Container';
import { Button } from '../../../components/Button';
import { CTA, Transaction } from '../styles';
import { Card } from '../../../components/Card';
import Title from '../../../components/Title';
import { ConsentimentoRequest } from '../../../context/ConsentimentoContext';
import { formatarCpfCnpj, formatarMoeda } from '../../../common/Helpers';
import { ModalContext } from '../../../context/ModalContext';
import { RecorrenteRequest } from '../../../context/RecorrenteContext';


const PixRecorrente = (): JSX.Element => {
    const history = useNavigate();
    const modal = useContext(ModalContext);
    const [multiConta, setMultiConta] = useState(false);
    const [valor, setValor] = useState("");
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [recebedor, setRecebedor] = useState("");
    const [instituicao, setInstituicao] = useState("");
    const [chavePIX, setChavePIX] = useState("");
    const [numeroPagamentos, setNumeroPagamentos] = useState("");
    const [frequencia, setFrequencia] = useState("");
    const [dataEncerramento, setDataEncerramento] = useState("");
    const [dataInicio, setDataInicio] = useState("");
    const [descricao, setDescricao] = useState("");
    const [pagarCom, setPagarCom] = useState("");
    const [formaPagamento, setFormaPagamento] = useState("");

    let contextConsentimento = useContext(ConsentimentoRequest);
    let contextRecorrente = useContext(RecorrenteRequest);

    const cancel = () => {
        let cancelModal: any = modal;
        cancelModal.update("CANCEL-RECURRING", { text: `Você será redirecionado de volta para ${instituicao}. Nenhum pagamento será realizado.`});
    };

    const confirm = () => {        
        let confirmModal: any = modal;
        confirmModal.update("RECURRING", { text: `Você será redirecionado de volta para ${instituicao}. O primeiro pagamento será realizado no dia ${dataInicio}.`});
    };

    useEffect(() => {
        if (!contextConsentimento.hasData) {
            history("/");
        }

        const dadosRecorrente = contextRecorrente;

        setValor(formatarMoeda(dadosRecorrente.valor));
        setFrequencia(dadosRecorrente.frequencia);
        setRecebedor(dadosRecorrente.recebedor.nomeRecebedor);
        setCpfCnpj(formatarCpfCnpj(dadosRecorrente.recebedor.cpf));
        setInstituicao(dadosRecorrente.recebedor.instituicao);
        setChavePIX(dadosRecorrente.recebedor.chavePix);
        
        setDataInicio(dadosRecorrente.primeiroPagamento);
        setDataEncerramento(dadosRecorrente.dadosAgendamento.dataEncerramento);
        setNumeroPagamentos(dadosRecorrente.dadosAgendamento.numeroPagamentos);
        setDescricao(dadosRecorrente.dadosAgendamento.descricao);
        setPagarCom(dadosRecorrente.dadosAgendamento.pagarCom);
        setFormaPagamento(dadosRecorrente.dadosAgendamento.formaPagamento);
    }, [contextConsentimento, contextRecorrente, history]);

    return (
        <Container>
            <SubContainer>
                <Title title="Confirmação">
                    <p>
                    A confirmação corresponde ao agendamento e não à efetivação do pagamento. <br/>
                    Você tem 5 minutos para confirmar esta transação.
                    </p>
                </Title>

                <Transaction>
                    <div>
                        <Card style={{minHeight: multiConta ? "376px" : ""}}>
                            <div className="card-wrapper">
                                <div className="card-body-line">
                                    <div>
                                        <h3 className="transaction-title">Valor</h3>
                                        <span id="value">{valor}</span>
                                        <span id="balance">Para {recebedor}</span>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Frequência</span>
                                            <span className="transaction-info">{frequencia}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Primeiro pagamento em</span>
                                            <span className="transaction-info">{dataInicio}</span>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className="transaction-title">Recebedor</h3>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Nome</span>
                                            <span className="transaction-info">{recebedor}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">{cpfCnpj.length > 14 ? "CNPJ" : "CPF"}</span>
                                            <span className="transaction-info">{cpfCnpj}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Instituição</span>
                                            <span className="transaction-info">{instituicao}</span>
                                        </div>
                                        { chavePIX &&
                                            <div className="transaction-info-area">
                                                <span className="transaction-info-title">Chave Pix</span>
                                                <span className="transaction-info">{chavePIX}</span>
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        <h3 className="transaction-title">Dados do agendamento</h3>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Começa em</span>
                                            <span className="transaction-info">{dataInicio}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Encerra em</span>
                                            <span className="transaction-info">{dataEncerramento}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Número de pagamentos</span>
                                            <span className="transaction-info">{numeroPagamentos}</span>
                                        </div >
                                        { descricao && 
                                            <div className="transaction-info-area">
                                                <span className="transaction-info-title">Nome da recorrência</span>
                                                <span className="transaction-info">{descricao}</span>
                                            </div>
                                        }
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Pagar com</span>
                                            <span className="transaction-info">{pagarCom}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Forma de pagamento</span>
                                            <span className="transaction-info">{formaPagamento}</span>
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                        </Card>
                        <p>Para que essa transação seja efetivada, você deve ter saldo disponível nas datas agendadas para o pagamento. Você pode cancelá-los até um dia antes.</p>
                    </div>
                </Transaction>                

                <CTA>
                    <Button className="full" value="Confirmar transação" onClick={confirm} />
                    <Button className="secondary full" value="Cancelar transação" onClick={cancel} />
                </CTA>
            </SubContainer>
        </Container>
    );
};

export default PixRecorrente;