import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { SubContainer } from '../../../components/Container';
import { useNavigate } from 'react-router-dom';
import { CTA, ErrorArea, Icon, Warning } from './styles';
import { Button } from '../../../components/Button';
import { ConsentimentoRequest } from '../../../context/ConsentimentoContext';
import { ModalContext } from '../../../context/ModalContext';
import alertIcon from '../../../assets/icons/ubr-alert-red.svg';
import Validator from '../../../common/Validator';
import { CSSObject } from 'styled-components';
import { CompartilhamentoContext } from '../../../context/CompartilhamentoContext';
import { seguranca } from '../../../services/seguranca.service';
import { PayloadAssinatura } from '../../../interfaces/compartilhamento';
import { AutomaticoRequest } from '../../../context/PagamentoAutomaticoContext';

type Props = {
    type: number;
    setSecretType: Dispatch<SetStateAction<number>>;
}

const Password = ({type, setSecretType} : Props): JSX.Element => {
    const history = useNavigate();
    let contextConsentimento = useContext(ConsentimentoRequest);
    let contextCompartilhamento = useContext(CompartilhamentoContext);
    let contextAutomatico = useContext(AutomaticoRequest);
    let modal = useContext(ModalContext);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [password, setPassword] = useState("");

    const inputStyle: CSSObject = {
        border: "1px solid var(--gray-dark)"
    };

    const cancel = () => {
        if (contextCompartilhamento.isShare) {
            modal.update("CANCEL-SHARE");
        } else if (contextConsentimento.hasData) {
            modal.update("CANCEL");
        } else {
            let cancelModal: any = modal;
            cancelModal.update("CANCEL-AUTOMATIC", { text: `Você será redirecionado de volta para ${contextAutomatico.dados.instituicao.nome}. Nenhuma transação será realizada.`});
        }
    };

    const confirm = async () => {
        if (!!password) {
            setIsLoading(true);
            if (contextCompartilhamento.isShare) {
                if (type == 1) {
                    Validator.authorizeShare(history, contextCompartilhamento, (response: any) => {
                        contextConsentimento.iniciadora.urlRedirecionamento = response.urlRedirect;
                        history("/redirect");
                    },
                    (errorMessage: string) => {
                        setIsLoading(false);
                        setErrorMsg(errorMessage);
                        setHasError(true);
                    });
                } else {
                    let payload: PayloadAssinatura = { assinaturaEletronica: password }
                    let promise = seguranca.assinaturaEletronica(payload);

                    promise.then(function () {
                        setSecretType(type);
                    })
                    .catch(function (error) {
                        setIsLoading(false);
                        setErrorMsg(error.response.data.message);
                        setHasError(true);
                    });
                }
            } else if (contextConsentimento.hasData) {
                Validator.authorize(modal, history, contextConsentimento, (response: any) => {
                    contextConsentimento.iniciadora = response;
                    history("/redirect");
                },
                (errorMessage: string) => {
                    setIsLoading(false);
                    setErrorMsg(errorMessage);
                    setHasError(true);
                });
            } else {
                Validator.authorizeAutomatic(modal, history, contextAutomatico, (response: any) => {
                    contextAutomatico.iniciadora = response;
                    history("/redirect");
                },
                (errorMessage: string) => {
                    setIsLoading(false);
                    setErrorMsg(errorMessage);
                    setHasError(true);
                });
            }
        }        
    };

    useEffect(() => {
        contextConsentimento.request.autenticacaoEletronica = password;
        contextCompartilhamento.payload.autenticacaoEletronica = password;
        contextAutomatico.payload.autenticacaoEletronica = password;
    }, [contextConsentimento, contextCompartilhamento, contextAutomatico, password]);

    return (
        <SubContainer>
            <input data-testid= "imputAssinatura" type="password" inputMode="numeric" placeholder="Assinatura eletrônica" maxLength={8} value={password} onChange={event => setPassword(event.target.value)} style={inputStyle} />

            <ErrorArea className={hasError ? "" : "hide"}>
                <Icon src={alertIcon} alt="Ícone" />
                <p>{errorMsg}</p>
            </ErrorArea>

            {contextCompartilhamento.isShare ?
                <Warning>
                    <strong>Importante:</strong> Ao confirmar o compartilhamento, você será
                    redirecionado para a instituição receptora.
                </Warning>
            :
                <Warning>
                    <strong>Importante:</strong> Ao confirmar a transação, você será redirecionado
                    e seu pagamento confirmado.
                </Warning>
            }

            <CTA>
                <Button value="Confirmar" onClick={confirm} disabled={isLoading}/>
                <Button className="secondary" value="Cancelar" onClick={cancel} />
            </CTA>
        </SubContainer>
    );
};

export default Password;