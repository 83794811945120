import { ReactChild } from "react";
import { CompartilhamentoProvider } from "../../context/CompartilhamentoContext";
import { ConsentimentoProvider } from "../../context/ConsentimentoContext";
import { ModalProvider } from "../../context/ModalContext";
import AuthContextProvider from "../../context/AuthContext";
import { ConsentimentoRecorrenteProvider } from "../../context/RecorrenteContext";
import { PagamentoAutomaticoProvider } from "../../context/PagamentoAutomaticoContext";

interface Props {
    children: ReactChild;
}

export function Providers ({ children }: Props) {
    
    return (
        <AuthContextProvider>
            <CompartilhamentoProvider>
                <ConsentimentoProvider>
                    <ConsentimentoRecorrenteProvider>
                        <PagamentoAutomaticoProvider>
                            <ModalProvider>
                                {children}
                            </ModalProvider>
                        </PagamentoAutomaticoProvider>
                    </ConsentimentoRecorrenteProvider>
                </ConsentimentoProvider>
            </CompartilhamentoProvider>
        </AuthContextProvider>
    );
}