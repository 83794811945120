import { Container, SubContainer } from '../../components/Container';
import lockIcon from '../../assets/icons/ubr-lock.svg'
import Password from './Password';
import QRCode from './QRCode';
import Token from './Token';
import Title from '../../components/Title';
import { CSSObject } from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { seguranca } from '../../services/seguranca.service';
import { ConsentimentoRequest } from '../../context/ConsentimentoContext';
import { useNavigate } from 'react-router-dom';
import { CompartilhamentoContext } from '../../context/CompartilhamentoContext';
import { AutomaticoRequest } from '../../context/PagamentoAutomaticoContext';


const Secret = (): JSX.Element => {
    const history = useNavigate();
    const contextConsentimento = useContext(ConsentimentoRequest);
    const contextCompartilhamento = useContext(CompartilhamentoContext);
    const contextAutomatico = useContext(AutomaticoRequest);
    const [showContent, setShowContent] = useState(false);
    const [secretType, setSecretType] = useState(1);
    const [type, setType] = useState(1);

    const secretDescription: Array<String> = [
        "Digite os 6 dígitos do código enviado por SMS para o seu celular",
        "Insira sua assinatura eletrônica no campo abaixo:",
        ""
    ];
    const content = [
        <Token />,
        <Password type={type} setSecretType={setSecretType}/>,
        <QRCode />
    ];
    const iconStyle: CSSObject = {
        width: "82px",
        height: "82px",
        marginBottom: "0px",
    };

 const buscaTipoDispositivo = async () => {
        let promise = seguranca.dispositivoSeguranca();
        
        promise.then(function (response) {
            let tipoDispositivo = response.data.tipo;
            let tipo  = 1;

            switch (tipoDispositivo) {
                case "TOKEN_SMS":
                    tipo = 0;
                    break;
                case "ASSINATURA_ELETRONICA":
                    tipo = 1;
                    break;
                default:
                    tipo = 2;
                    break;
            }

            contextCompartilhamento.isShare ? setType(tipo) : setSecretType(tipo);
            setShowContent(true);
        })
        .catch(function (error) {
            console.error(error);
            history("/response");
        });
    };

    useEffect(() => {
        if (!contextCompartilhamento.isShare && !contextConsentimento.hasData && !contextAutomatico.hasData) {
            history("/");
        }
        
        buscaTipoDispositivo();
    }, [contextConsentimento, history]);

    return (
        <Container>
            <SubContainer>
                {showContent ?
                    <>
                        <Title title="Para sua segurança" icon={lockIcon} iconStyle={iconStyle}>
                            <p className={secretDescription[secretType].length > 0 ? "" : "hide"}>
                                {secretDescription[secretType]}
                            </p>
                        </Title>

                        {content[secretType]}
                    </> :
                    <Title title="Carregando..." icon={lockIcon} iconStyle={iconStyle} />
                }
            </SubContainer>
        </Container>
    );
};

export default Secret;