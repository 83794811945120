import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, SubContainer } from '../../../components/Container';
import { Button } from '../../../components/Button';
import { CTA, Transaction } from '../styles';
import { Card } from '../../../components/Card';
import Title from '../../../components/Title';
import { formatarCpfCnpj, formatarNumConta } from '../../../common/Helpers';
import { ModalContext } from '../../../context/ModalContext';
import { AutomaticoRequest } from '../../../context/PagamentoAutomaticoContext';


const PixAutomatico = (): JSX.Element => {
    const history = useNavigate();
    const modal = useContext(ModalContext);
    const [multiConta, setMultiConta] = useState(false);
    const [instituicao, setInstituicao] = useState("");
    const [horaLimite, setHoraLimite] = useState("");
    const [dataInicio, setDataInicio] = useState("");
    const [nome, setNome] = useState("");
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [instituicaoOrigem, setInstituicaoOrigem] = useState("");
    const [contaOrigem, setContaOrigem] = useState("");
    const [agenciaOrigem, setAgenciaOrigem] = useState("");
    const [formaPagamento, setFormaPagamento] = useState("");
    const [descricao, setDescricao] = useState("");
    const [gatilhos, setGatilhos] = useState<string[]>([]);
    const [dataEncerramento, setDataEncerramento] = useState("");
    const [idVinculo, setIdVinculo] = useState("");
    const [limiteVinculo, setLimiteVinculo] = useState("");
    const [limiteTransacao, setLimiteTransacao] = useState("");

    let contextAutomatico = useContext(AutomaticoRequest);

    const cancel = () => {
        let cancelModal: any = modal;
        cancelModal.update("CANCEL-AUTOMATIC", { text: `Você será redirecionado de volta para ${instituicao}. Nenhuma transação será realizada.`});
    };

    const confirm = () => {        
        let confirmModal: any = modal;
        confirmModal.update("AUTOMATIC", { text: `Você será redirecionado de volta para ${instituicao}. As transferências serão realizadas conforme os gatilhos definidos na instituição iniciadora.`});

    };

    useEffect(() => {
        if (!contextAutomatico.hasData) {
            history("/");
        }

        const dadosAutomatico = contextAutomatico.dados;

        setDataInicio(dadosAutomatico.dataInicio);
        setInstituicao(dadosAutomatico.instituicao.nome);
        setHoraLimite(dadosAutomatico.horaLimite);

        setCpfCnpj(formatarCpfCnpj(dadosAutomatico.cpf));
        setNome(dadosAutomatico.nome);

        setInstituicaoOrigem(dadosAutomatico.contaPagadora.nome);
        setContaOrigem(formatarNumConta(dadosAutomatico.contaPagadora.contaCorrente));
        setAgenciaOrigem(dadosAutomatico.contaPagadora.agencia);
        setFormaPagamento(dadosAutomatico.contaPagadora.formaPagamento);

        setDescricao(dadosAutomatico.dadosVinculo.descricao);
        setGatilhos(dadosAutomatico.dadosVinculo.gatilhos);
        setDataEncerramento(dadosAutomatico.dadosVinculo.dataEncerramento);
        setIdVinculo(dadosAutomatico.dadosVinculo.idVinculo);
        setLimiteVinculo(dadosAutomatico.dadosVinculo.limiteVinculo);
        setLimiteTransacao(dadosAutomatico.dadosVinculo.limiteTransacao);
    }, [contextAutomatico, history]);

    return (
        <Container>
            <SubContainer>
                <Title title="Confirmação">
                    <p>
                    A confirmação corresponde ao vínculo entre contas e não à efetivação do pagamento. 
                    Você tem até às {horaLimite} para autorizar esse vínculo.
                    </p>
                </Title>

                <Transaction>
                    <div>
                        <Card style={{minHeight: multiConta ? "376px" : ""}}>
                            <div className="card-wrapper">
                                <div className="card-body-line">
                                    <div>
                                        <h3 className="transaction-title">Vínculo com</h3>
                                        <span id="value">{instituicao}</span>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Data de início</span>
                                            <span className="transaction-info">{dataInicio}</span>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className="transaction-title">Titular</h3>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Nome</span>
                                            <span className="transaction-info">{nome}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">CPF</span>
                                            <span className="transaction-info">{cpfCnpj}</span>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className="transaction-title">Conta de origem</h3>
                                        <span className="transaction-description">O seu dinheiro sairá da conta de origem.</span>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Instituição</span>
                                            <span className="transaction-info">{instituicaoOrigem}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Conta corrente</span>
                                            <span className="transaction-info">{contaOrigem}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Agência</span>
                                            <span className="transaction-info">{agenciaOrigem}</span>
                                        </div >
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Forma de pagamento</span>
                                            <span className="transaction-info">{formaPagamento}</span>
                                        </div>
                                    </div>     
                                    <div>
                                        <h3 className="transaction-title">Dados do vínculo</h3>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Descrição do vínculo</span>
                                            <span className="transaction-info">{descricao}</span>
                                        </div>
                                        { gatilhos && gatilhos.length > 0 && 
                                            <div className="transaction-info-area">
                                                <span className="transaction-info-title">Gatilhos para transferência</span>
                                                <ul>
                                                    {
                                                        gatilhos.map((item, key) => (
                                                            <li key={key}>{item}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        }
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Começa em</span>
                                            <span className="transaction-info">{dataInicio}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Encerra em</span>
                                            <span className="transaction-info">{dataEncerramento}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">ID do vínculo</span>
                                            <span className="transaction-info">{idVinculo}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Limite do vínculo</span>
                                            <span className="transaction-info">{limiteVinculo}</span>
                                        </div>
                                        <div className="transaction-info-area">
                                            <span className="transaction-info-title">Limite por transação</span>
                                            <span className="transaction-info">{limiteTransacao}</span>
                                        </div>
                                    </div>                                 
                                </div>
                            </div>
                        </Card>
                        <p>
                        Para que as transações sejam efetivadas, você deve ter saldo disponível em sua conta. 
                        Você pode cancelar o vínculo a qualquer momento.
                        </p>
                    </div>
                </Transaction>                

                <CTA>
                    <Button className="full" value="Confirmar vínculo" onClick={confirm} />
                    <Button className="secondary full" value="Cancelar vínculo" onClick={cancel} />
                </CTA>
            </SubContainer>
        </Container>
    );
};

export default PixAutomatico;