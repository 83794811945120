import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, SubContainer } from '../../../components/Container';
import { Button } from '../../../components/Button';
import { CTA, Description, Transaction } from '../styles';
import { Card } from '../../../components/Card';
import Title from '../../../components/Title';
import { ConsentimentoRequest } from '../../../context/ConsentimentoContext';
import { formatarCpfCnpj, formatarDataHora, formatarMoeda, formatarNumConta, formatarTipoConta } from '../../../common/Helpers';
import { ModalContext } from '../../../context/ModalContext';


const Pix = (): JSX.Element => {
    const history = useNavigate();
    const modal = useContext(ModalContext);
    const [multiConta, setMultiConta] = useState(false);
    const [valor, setValor] = useState("R$ 0,00");
    const [cpfCnpj, setCpfCnpj] = useState("CNPJ 12.345.678/0001-40");
    const [credor, setCredor] = useState("Lojista 1");
    const [data, setData] = useState("01/01/2021");
    const [expiraData, setExpiraData] = useState("01/01/2021");
    const [devedorTipoConta, setDevedorTipoConta] = useState("Conta Corrente");
    const [devedorConta, setDevedorConta] = useState("12345-6");
    let contextConsentimento = useContext(ConsentimentoRequest);

    const cancel = () => {
        modal.update("CANCEL");
    };

    const confirm = () => {
        history("/segredo");
    };

    useEffect(() => {
        if (!contextConsentimento.hasData) {
            history("/");
        }

        const dadosConsentimento = contextConsentimento.request.consentimento;

        setMultiConta(contextConsentimento.duplaAssinatura);
        setValor(formatarMoeda(parseFloat(dadosConsentimento.payment.amount)));
        setCpfCnpj(formatarCpfCnpj(dadosConsentimento.creditor.cpfCnpj));
        setCredor(dadosConsentimento.creditor.name);
        setData(formatarDataHora("Data", dadosConsentimento.payment.date.replace(/-/g, "/")));
        setExpiraData(formatarDataHora("Data", dadosConsentimento.expirationDateTime));
        setDevedorTipoConta(formatarTipoConta(dadosConsentimento.debtorAccount.accountType));
        setDevedorConta(formatarNumConta(dadosConsentimento.debtorAccount.number));
    }, [contextConsentimento, history]);

    return (
        <Container>
            <SubContainer>
                <Title title="Confirmação">
                    <p>
                        Revise os dados abaixo para continuar com a transação.<br/>
                        Você possui até 5 minutos para confirmá-la.
                    </p>
                </Title>

                <Transaction>
                    <div>
                        <Card style={{minHeight: multiConta ? "376px" : ""}}>
                            <div className="card-wrapper">
                                <div className="card-body-double-col">
                                    <div>
                                        <h3>Valor a pagar:</h3>
                                        <span id="value">{valor}</span>
                                    </div>

                                    <div>
                                        <h3>Dados do recebedor:</h3>
                                        <span className="transaction-info">{`${cpfCnpj.length > 14 ? "CNPJ" : "CPF"} ${cpfCnpj}`}</span>
                                        <span className="transaction-info">{credor}</span>
                                    </div>

                                    <div>
                                        <h3>Data de pagamento:</h3>
                                        <span className="transaction-info">{data}</span>
                                    </div>

                                    <div>
                                        <h3>Fonte de pagamento:</h3>
                                        <span className="transaction-info">{devedorTipoConta} {devedorConta}</span>
                                    </div>

                                    <div>
                                        <h3>Forma de pagamento:</h3>
                                        <span className="transaction-info">Pix - Open Finance</span>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    
                    <div className={multiConta ? "info-multi" : "hide"}>
                        <Description>
                            <h2>Autorização</h2>
                            <p>
                                Identificamos que será necessária uma ou mais autorizações para
                                concluir esta transação.
                            </p>
                        </Description>

                        <Card>
                            <div className="card-wrapper">
                                <div className='card-header'>
                                    <h2>Importante</h2>
                                    <p>
                                        A transação só será efetivada após a autorização de
                                        todos os aprovadores.
                                    </p>
                                </div>
                                <div className='card-body center'>
                                    <p>Os aprovados tem até:</p>

                                    <div className="authorization-limit">
                                        <span>{expiraData}</span>
                                        &nbsp;às&nbsp;
                                        <span>23:59</span>
                                    </div>

                                    <p>
                                        para autorizar a transação nos canais da Unicred.<br />
                                        Após esse prazo deverá ser feita uma nova ordem de pagamento.
                                    </p>
                                </div>
                            </div>
                        </Card>  
                                       
                     </div>
                </Transaction>                

                <CTA>
                    <Button className="full" value="Confirmar transação" onClick={confirm} />
                    <Button className="secondary full" value="Cancelar transação" onClick={cancel} />
                </CTA>
            </SubContainer>
        </Container>
    );
};

export default Pix;