import React, { ReactNode } from 'react';
import { AgendamentoRecorrente } from '../interfaces/recorrente';

interface Props {
    children: ReactNode
}

let dadosConsentimentoRecorrente: AgendamentoRecorrente = {
    valor: 0,
    frequencia: "",
    primeiroPagamento: "",
    recebedor: {
        nomeRecebedor: "",
        cpf: "",
        instituicao: "",
        chavePix: ""
    },
    dadosAgendamento: {
        dataInicio: "",
        dataEncerramento: "",
        numeroPagamentos: "",
        pagarCom: "",
        formaPagamento: "",
        descricao: "",
    },    
};

export const RecorrenteRequest = React.createContext(dadosConsentimentoRecorrente);

export const ConsentimentoRecorrenteProvider = ({ children }: Props) => {

    return (
        <RecorrenteRequest.Provider value={dadosConsentimentoRecorrente}>
            {children}
        </RecorrenteRequest.Provider>
    );
}