import { useNavigate } from 'react-router-dom';
import { Container, SubContainer } from '../../components/Container';
import Title from '../../components/Title';
import { useCallback, useContext, useEffect } from 'react';
import { CompartilhamentoContext } from '../../context/CompartilhamentoContext';
import { ConsentimentoRequest } from '../../context/ConsentimentoContext';
import { DadosCompartilhamento } from '../../interfaces/compartilhamento';
import { validacaoConsentimento } from '../../interfaces/consentimento';
import { DadosConsentimentos } from '../../interfaces/consentimentos';
import { consentimentos } from '../../services/consentimentos.service';
import { DadosRecorrente } from '../../interfaces/recorrente';
import {  RecorrenteRequest } from '../../context/RecorrenteContext';
import { DadosPagamentoAutomatico } from '../../interfaces/automatico';
import { AutomaticoRequest } from '../../context/PagamentoAutomaticoContext';

const Loading = (): JSX.Element => {
  const history = useNavigate();
  let contextCompartilhamento = useContext(CompartilhamentoContext);
  let contextConsentimento = useContext(ConsentimentoRequest);
  let contextRecorrente = useContext(RecorrenteRequest);
  let contextAutomatico = useContext(AutomaticoRequest);

  const errorTreatment = (response: any) => {
    if (response) {
      if (response.status === 420) {
        history('/responses/expirado');
      } else if (response.status === 422 || response.status === 500) {
        if (!!response.data.details) {
          if (response.status === 422 && response.data.details[0].message === "CONSENTIMENTO_DADOS") {
            contextCompartilhamento.isShare = true;
          }
        }
        contextConsentimento.errorMessage = response.data.message;
        history('/responses/alerta');
      } else {
        history('/responses');
      }
    } else {
      history('/responses');
    }
  
  };
  
  const dadosCompartilhamento = (dados: DadosCompartilhamento) => {
    contextCompartilhamento.isShare = true;
    if (!!dados.id) {
      if (dados.status === 'Expirado') {
        history('/responses/expirado');
      } else {
        contextCompartilhamento.dados = dados;
        contextCompartilhamento.hasData = true;
        history('/compartilhamento');
      }
    } else {
      history('/responses');
    }
  }
  
  const dadosConsentimento = (dados: validacaoConsentimento) => {
    if (!!dados.consentimento) {
      const dadosConsentimento = dados.consentimento;  
      contextConsentimento.duplaAssinatura = dados.duplaAssinatura;
      contextConsentimento.request.consentimento = dadosConsentimento;
      contextConsentimento.hasData = true;
  
      if (dadosConsentimento.payment.type === 'PIX') {
        if (!!dadosConsentimento.payment.schedule) {
          contextConsentimento.request.codTransacao = 169;
          history('/pix/agendamento');
        } else {
          contextConsentimento.request.codTransacao = 169;
          history('/pix');
        }
      } else {
        history('/responses');
      }
    } else {
      history('/responses');
    }
  }

  const dadosRecorrente = (dados: DadosRecorrente) => {
    if (!!dados) {
      contextRecorrente.valor = dados.valor;
      contextRecorrente.frequencia = dados.frequencia;
      contextRecorrente.primeiroPagamento = dados.primeiroPagamento;
      contextRecorrente.dadosAgendamento = dados.dadosAgendamento;
      contextRecorrente.recebedor = dados.recebedor;

      contextConsentimento.request.consentimento = dados.consentimento;
      contextConsentimento.hasData = true;
      history('/pix/recorrente');
    } else {
      history('/responses');
    }
  }

  const dadosAutomatico = (dados: DadosPagamentoAutomatico) => {
    if (!!dados) {
      contextAutomatico.hasData = true;
      contextAutomatico.dados = dados;
      history('/pix/automatico');
    } else {
      history('/responses');
    }
  }
  
  const buscaDadosConsentimentos = useCallback(async () => {
    const responseDados = consentimentos.buscaDadosConsentimentos();

    responseDados
      .then(response => {
        if (response !== undefined) {
          const dados: DadosConsentimentos = response.data;
  
          if (dados.tipo === "CONSENTIMENTO_PAGAMENTO") {
            dadosConsentimento(dados.consentimentoPagamentoDTO);
          } else if (dados.tipo === "CONSENTIMENTO_DADOS") {
            dadosCompartilhamento(dados.consentimentoDadosDTO);
          } else if (dados.tipo === "CONSENTIMENTO_RECORRENTE") {
            dadosRecorrente(dados.consentimentoRecorrenteDTO);
          } else if (dados.tipo === "CONSENTIMENTO_AUTOMATICO") {
            dadosAutomatico(dados.consentimentoPagamentoAutomatico);
          } else {
            history("/responses");
          }
        }
      })
      .catch(error => {
        errorTreatment(error.response);
      });
  }, []);
  
  useEffect(() => {
    buscaDadosConsentimentos();
  });

  return (
    <Container>
      <SubContainer>
          <Title title="Carregando">
            <p>Aguarde enquanto carregamos os dados de sua solicitação.</p>
          </Title>
      </SubContainer>
    </Container>
  );
};

export default Loading;
