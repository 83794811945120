import { useContext, useEffect, useState } from 'react';
import { SubContainer } from '../../../components/Container';
import { useNavigate } from 'react-router-dom';
import { CTA, ErrorArea, Icon, TokenArea, Warning } from './styles';
import { Button } from '../../../components/Button';
import { ConsentimentoRequest } from '../../../context/ConsentimentoContext';
import { CompartilhamentoContext } from '../../../context/CompartilhamentoContext';
import { seguranca } from '../../../services/seguranca.service';
import { ModalContext } from '../../../context/ModalContext';
import alertIcon from '../../../assets/icons/ubr-alert-red.svg';
import Validator from '../../../common/Validator';
import { AutomaticoRequest } from '../../../context/PagamentoAutomaticoContext';


const Token = (): JSX.Element => {
    const history = useNavigate();
    let contextConsentimento = useContext(ConsentimentoRequest);
    let contextCompartilhamento = useContext(CompartilhamentoContext);
    let contextAutomatico = useContext(AutomaticoRequest);
    let modal = useContext(ModalContext);
    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [token1, setToken1] = useState("");
    const [token2, setToken2] = useState("");
    const [token3, setToken3] = useState("");
    const [token4, setToken4] = useState("");
    const [token5, setToken5] = useState("");
    const [token6, setToken6] = useState("");

    const cancel = () => {
        if (contextCompartilhamento.isShare) {
            modal.update("CANCEL-SHARE");
        } else if (contextConsentimento.hasData) {
            modal.update("CANCEL");
        } else {
            let cancelModal: any = modal;
            cancelModal.update("CANCEL-AUTOMATIC", { text: `Você será redirecionado de volta para ${contextAutomatico.dados.instituicao.nome}. Nenhuma transação será realizada.`});
        }
    };

    const validaToken = () => {
        let valido = false;
        if (!!token1 && !!token2 && !!token3 && !!token4 && !!token5 && !!token6) {
            valido = true;
        }
        return valido;
    }

    const confirm = () => {
        if (validaToken()) {
            if (contextCompartilhamento.isShare) {
                Validator.authorizeShare(history, contextCompartilhamento, (response: any) => {
                    contextConsentimento.iniciadora.urlRedirecionamento = response.urlRedirect;
                    history("/redirect");
                },
                (errorMessage: string) => {
                    setErrorMsg(errorMessage);
                    setHasError(true);
                });
            } else if (contextConsentimento.hasData) {
                Validator.authorize(modal, history, contextConsentimento, (response: any) => {
                    contextConsentimento.iniciadora = response;
                    history("/redirect");
                },
                (errorMessage: string) => {
                    setErrorMsg(errorMessage);
                    setHasError(true);
                });
            } else {
                Validator.authorizeAutomatic(modal, history, contextAutomatico, (response: any) => {
                    contextAutomatico.iniciadora = response;
                    history("/redirect");
                },
                (errorMessage: string) => {
                    setErrorMsg(errorMessage);
                    setHasError(true);
                });
            }
        }
    };

    const sendSMS = async () => {
        let promise = seguranca.enviarSMS();
        promise.then(response => {
            console.info("SMS enviado!", response);
            setToken1("");
            setToken2("");
            setToken3("");
            setToken4("");
            setToken5("");
            setToken6("");
            setErrorMsg("");
            setHasError(false);
        })
        .catch(error => {
            if (error.response) {
                setErrorMsg(error.response.data.message);
                setHasError(true);
            } else {
                console.error(error);
            }
        });
    }

    const nextToken = (event: any, setValue: Function) => {
        let target: HTMLInputElement = event.target;
        let value: String = target.value;
        let lastChar: String = "";

        const elemId: string = target.id;
        const re: RegExp = /[A-Z]+(\d)+/gi;
        let result: RegExpExecArray | null;
        let targetId: number = 1;

        result = re.exec(elemId);

        if (result && result[1]) {
            targetId = parseInt(result[1]);
        } else {
            console.error("Input não tem um ID!")
        }

        if (value?.length > 1) {
            if (event?.keyCode !== 8 && event?.keyCode !== 46) {
                lastChar = value.substr(value.length - 1);
                setValue(lastChar);

                document.getElementById("token" + (targetId + 1))?.focus();
            }
        } else {
            setValue(value);

            if (value !== "") {
                document.getElementById("token" + (targetId + 1))?.focus();
            }
        }
    };

    useEffect(() => {
        sendSMS();
    }, []);

    useEffect(() => {
        contextConsentimento.request.tokenSMS = token1 + token2 + token3 + token4 + token5 + token6;
        contextCompartilhamento.payload.tokenSMS = token1 + token2 + token3 + token4 + token5 + token6;
        contextAutomatico.payload.tokenSMS = token1 + token2 + token3 + token4 + token5 + token6;
    }, [contextConsentimento, contextCompartilhamento, contextAutomatico, token1, token2, token3, token4, token5, token6]);

    return (
        <SubContainer>
            <TokenArea className={hasError ? "error": ""}>
                <div data-testid="campos_tokenInit">
                    <input data-testid='token_um' id="token1" type="number" value={token1} onChange={event => nextToken(event, setToken1)} />
                    <input data-testid='token_dois' id="token2" type="number" value={token2} onChange={event => nextToken(event, setToken2)} />
                    <input data-testid='token_tres' id="token3" type="number" value={token3} onChange={event => nextToken(event, setToken3)} />
                </div>
                <div data-testid="campos_tokenfinal">
                    <input data-testid='token_quatro' id="token4" type="number" value={token4} onChange={event => nextToken(event, setToken4)} />
                    <input data-testid='token_cinco' id="token5" type="number" value={token5} onChange={event => nextToken(event, setToken5)} />
                    <input data-testid='token_seis' id="token6" type="number" value={token6} onChange={event => nextToken(event, setToken6)} />
                </div>
            </TokenArea>

            <ErrorArea className={hasError ? "" : "hide"}>
                <Icon src={alertIcon} alt="Ícone" />
                <p>{ errorMsg }</p>
            </ErrorArea>

            {contextCompartilhamento.isShare ?
                <Warning>
                    <strong>Importante:</strong> Ao confirmar o compartilhamento, você será
                    redirecionado para a instituição receptora.
                </Warning>
            :
                <Warning>
                    <strong>Importante:</strong> Ao confirmar a transação, você será redirecionado
                    e seu pagamento confirmado.
                </Warning>
            }

            <CTA>
                <Button value="Confirmar" onClick={confirm} />
                <Button className="secondary" value="Reenviar SMS" onClick={sendSMS} />
                <Button className="secondary alt" value="Cancelar" onClick={cancel} />
            </CTA>
        </SubContainer>
    );
};

export default Token;