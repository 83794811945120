import { ContextPagamentoAutomatico } from "../interfaces/automatico";
import { ContextCompartilhamento } from "../interfaces/compartilhamento";
import { ContextConsentimento } from "../interfaces/consentimento";
import { pagamentoAutomatico } from "../services/automatico.service";
import { compartilhamento } from "../services/compartilhamento.service";
import { consentimento } from "../services/consentimento.service";
import { consentimentos } from "../services/consentimentos.service";

const Validator = {
    authorize: async (modal: any, history: any, consent: ContextConsentimento, success: Function, failure: Function) => {
        const timeout = setTimeout(() => {
            history("/responses/processando");
        }, 10000);

        const promise = consentimento.atualizaConsentimento("AUTORIZADO", consent.request);

        promise.then(response => {
            clearTimeout(timeout);
            success(response.data);
        })
        .catch(error => {
            clearTimeout(timeout);

            if (error.response) {
                const status = error.response.status;
                const message = error.response.data.message;

                if (status === 300) {
                    modal.update("OVERDRAFT", { text: message });
                } else if (status === 420) {
                    history("/responses/expirado");
                } else if (status === 403 || status === 422 || status === 423) {
                    failure(message);
                } else if (status === 428) {
                    modal.update("FUNDS");
                } else {
                    history("/responses");
                }
            } else {
                failure(error.message);
            }
        });
    },

    authorizeShare: async (history: any, share: ContextCompartilhamento, success: Function, failure: Function) => {
        const timeout = setTimeout(() => {
            history("/responses/processando");
        }, 10000);

        const promise = compartilhamento.confirmaCompartilhamento(share.payload);

        promise.then(response => {
            clearTimeout(timeout);
            success(response.data);
        })
        .catch(error => {
            clearTimeout(timeout);

            if (error.response) {
                const status = error.response.status;
                const message = error.response.data.message;

                if (status === 420) {
                    history("/responses/expirado");
                } else if (status === 403 || status === 422 || status === 423) {
                    failure(message);
                } else {
                    history("/responses");
                }
            } else {
                failure(error.message);
            }
        });
    },

    authorizeAutomatic: async (modal: any, history: any, consent: ContextPagamentoAutomatico, success: Function, failure: Function) => {
        const timeout = setTimeout(() => {
            history("/responses/processando");
        }, 10000);

        const promise = pagamentoAutomatico.atualizaPagamentoAutomatico(consent.dados.recurringConsentId, "AUTORIZADO", consent.payload);

        promise.then(response => {
            clearTimeout(timeout);
            success(response.data);
        })
        .catch(error => {
            clearTimeout(timeout);

            if (error.response) {
                const status = error.response.status;
                const message = error.response.data.message;

                if (status === 420) {
                    history("/responses/expirado");
                } else if (status === 403 || status === 422 || status === 423) {
                    failure(message);
                } else if (status === 428) {
                    modal.update("FUNDS");
                } else {
                    history("/responses");
                }
            } else {
                failure(error.message);
            }
        });
    },

    cancel: async (history: any, consent: ContextConsentimento, success: Function) => {
        const promise = consentimento.atualizaConsentimento("REJEITADO", consent.request);

        promise.then(response => {
            success(response.data);
        })
        .catch(function (error) {
            console.error(error);
            history("/responses");
        });
    },

    cancelShare: async (history: any, shareId: string, success: Function) => {
        const promise = compartilhamento.atualizaCompartilhamento(shareId, "REJEITADO");

        promise.then(response => {
            success(response.data);
        })
        .catch(function (error) {
            console.error(error);
            history("/responses");
        });
    },

    reject: async (history: any, success: Function) => {
        const promise = consentimentos.rejeitaConsentimentos();

        promise.then(response => {
            success(response.data);
        })
        .catch(function (error) {
            console.error(error);
            history("/responses");
        });
    },

    cancelAutomatic: async (automatic: ContextPagamentoAutomatico, history: any, success: Function) => {
        const promise = pagamentoAutomatico.atualizaPagamentoAutomatico(automatic.dados.recurringConsentId, "CANCELADO", automatic.payload);

        promise.then(response => {
            success(response.data);
        })
        .catch(function (error) {
            console.error(error);
            history("/responses");
        });
    }
};

export default Validator;