import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, SubContainer } from '../../../components/Container';
import { Button } from '../../../components/Button';
import { CTA, Description, Transaction } from '../styles';
import { Card } from '../../../components/Card';
import Title from '../../../components/Title';
import { ConsentimentoRequest } from '../../../context/ConsentimentoContext';
import { formatarCpfCnpj, formatarDataHora, formatarMoeda, formatarNumConta, formatarTipoConta } from '../../../common/Helpers';
import { ModalContext } from '../../../context/ModalContext';


const Agendamento = (): JSX.Element => {
    const history = useNavigate();
    const modal = useContext(ModalContext);
    const [multiConta, setMultiConta] = useState(false);
    const [valor, setValor] = useState("");
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [beneficiario, setBeneficiario] = useState("");
    const [chavePix, setChavePix] = useState("");
    const [dataAgendada, setDataAgendada] = useState("");
    const [dataIniciacao, setDataIniciacao] = useState("");
    const [expiraData, setExpiraData] = useState("");
    const [devedorTipoConta, setDevedorTipoConta] = useState("");
    const [devedorConta, setDevedorConta] = useState("");
    let contextConsentimento = useContext(ConsentimentoRequest);

    const cancel = () => {
        modal.update("CANCEL");
    };

    const confirm = () => {
        history("/segredo");
    };

    useEffect(() => {
        if (!contextConsentimento.hasData) {
            history("/");
        }

        const dadosConsentimento = contextConsentimento.request.consentimento;
        const agendamento = dadosConsentimento.payment.schedule;

        setMultiConta(contextConsentimento.duplaAssinatura);
        setBeneficiario(dadosConsentimento.creditor.name);
        setChavePix(dadosConsentimento.payment.details.proxy);
        
        if(!!agendamento && !!agendamento.single && !!agendamento.single.date){
            setDataAgendada(formatarDataHora("Data", agendamento.single.date));
        } else {
            console.error("Não foi possível pegar a data de agendamento.");
        }
        
        setDataIniciacao(formatarDataHora("Data-Hora", dadosConsentimento.creationDateTime));
        setValor(formatarMoeda(parseFloat(dadosConsentimento.payment.amount)));
        setCpfCnpj(formatarCpfCnpj(dadosConsentimento.creditor.cpfCnpj));
        setExpiraData(formatarDataHora("Data", dadosConsentimento.expirationDateTime));
        setDevedorTipoConta(formatarTipoConta(dadosConsentimento.debtorAccount.accountType));
        setDevedorConta(formatarNumConta(dadosConsentimento.debtorAccount.number));
    }, [contextConsentimento, history]);

    return (
        <Container>
            <SubContainer>
                <Title title="Confirmação">
                    <p>
                        Revise os dados abaixo para continuar com a transação.<br/>
                        Você possui até 5 minutos para confirmá-la.
                    </p>
                </Title>

                <Transaction>
                      <div>
                        <Card style={{minHeight: multiConta ? "385px" : ""}}>
                            <div className="card-wrapper">
                                <div className="card-body-double-col">
                                    <div>
                                        <h3>Valor a pagar:</h3>
                                        <span id="value">{valor}</span>
                                    </div>

                                    <div style={{width:"100%"}}>
                                        <h3>Data agendada para o pagamento</h3>
                                        <span className="transaction-info" style={{paddingBottom:"10px"}}>{dataAgendada}</span>
                                        <span className="message-info">A transação estará sujeita à disponibilidade de saldo no momento da efetivação do pagamento e você pode revogar até um dia antes via app Unicred Mobile.</span>
                                    </div>

                                    <div>
                                        <h3>Dados do recebedor:</h3>
                                        <span className="transaction-info">{`${cpfCnpj.length > 14 ? "CNPJ" : "CPF"} ${cpfCnpj}`}</span>
                                    </div>

                                    <div>
                                       <h3>Chave Pix:</h3>
                                        <span className="transaction-info">{chavePix}</span>
                                    </div>

                                    <div>
                                        <h3>Beneficiário:</h3>
                                        <span className="transaction-info">{beneficiario}</span>
                                        
                                    </div>
                                    <div>
                                       <h3>Descrição:</h3>
                                        <span className="transaction-info">Pix agendado</span>
                                    </div>

                                    <div>
                                        <h3>Fonte de pagamento:</h3>
                                        <span className="transaction-info">{devedorTipoConta} {devedorConta}</span>
                                        
                                    </div>

                                    <div>
                                        <h3>Data iniciação de pagamento:</h3>
                                        <span className="transaction-info">{dataIniciacao} </span>
                                    </div>                                   

                                    <div style={{width:"100%"}}>
                                        <h3>Forma de pagamento:</h3>
                                        <span className="transaction-info">Pix - Open Finance</span>
                                    </div>
                                </div>
                            </div>
                        </Card>    
                        <div className={!multiConta ? 'info-card' : "hide"}>
                            <p>
                                A confirmação diz respeito ao agendamento da transação e não à efetivação do pagamento em si.
                            </p>
                        </div>      

                    </div>

                    <div data-testid="dupla_Assinatura"className={multiConta ? "info-multi" : "hide"}>
                        <Description>
                            <h2>Autorização</h2>
                            <p>
                                Identificamos que será necessária uma ou mais autorizações para
                                concluir esta transação.
                            </p>
                        </Description>

                        <Card>
                            <div className="card-wrapper">
                                <div className='card-header'>
                                    <h2>Importante</h2>
                                    <p>
                                        A transação só será efetivada após a autorização de
                                        todos os aprovadores.
                                    </p>
                                </div>
                                <div className='card-body center'>
                                    <p>Os aprovados tem até:</p>

                                    <div className="authorization-limit">
                                        <span>{expiraData}</span>
                                        &nbsp;às&nbsp;
                                        <span>23:59</span>
                                    </div>

                                    <p>
                                        para autorizar a transação nos canais da Unicred.
                                        Após esse prazo deverá ser feita uma nova ordem de pagamento.
                                    </p>
                                </div>
                            </div>
                        </Card>     
                        <div className='info-card'>
                            <p>
                                A confirmação diz respeito ao agendamento da transação e não à efetivação do pagamento em si.
                            </p>
                        </div>                   
                     </div>

                </Transaction>    
                            
                <CTA>
                    <Button className="full" value="Confirmar transação" onClick={confirm} />
                    <Button className="secondary full" value="Cancelar transação" onClick={cancel} />
                </CTA>
            </SubContainer>
        </Container>
    );
};
export default Agendamento;