import { Routes, Route } from 'react-router-dom';
import Callback from './pages/Callback';
import Home from './pages/Home';
import Messages from './pages/Messages';
import PixAgendamento from './pages/Description/Pix/Agendamento';
import Pix from './pages/Description/Pix/index';
import Redirect from './pages/Redirect';
import Secret from './pages/Secret';
import Share from './pages/Description/Share';
import MainLayout from './pages/MainLayout';
import Loading from './pages/Loading';
import PixRecorrente from './pages/Description/Pix/Recorrente';
import PixAutomatico from './pages/Description/Pix/Automatico';

const Rotas = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route element={<MainLayout />}>
        <Route path="carregando" element={<Loading />} />
        <Route path="callback" element={<Callback />} />
        <Route path="compartilhamento" element={<Share />} />
        <Route path="redirect" element={<Redirect />} />
        <Route path="segredo" element={<Secret />} />
        <Route path="pix" element={<Pix />} />
        <Route path="pix/agendamento" element={<PixAgendamento />} />
        <Route path="pix/recorrente" element={<PixRecorrente />} />
        <Route path="pix/automatico" element={<PixAutomatico />} />
        <Route path="responses" element={<Messages />}>
          <Route path=":type" element={<Messages />} />
        </Route>
        <Route path="*" element={<Messages />} />
      </Route>
    </Routes>
  );
};

export default Rotas;
