import React, { ReactNode } from 'react';
import { ContextPagamentoAutomatico } from '../interfaces/automatico';

interface Props {
    children: ReactNode
}

let dadosPagamentoAutomatico: ContextPagamentoAutomatico = {
    hasData: false,
    dados: {
        instituicao: {
            nome: "",
            cnpj: "",
            icone: "",
            id: ""
        },
        id: "",
        dataInicio: "",
        horaLimite: "",
        nome: "",
        cpf: "",
        recurringConsentId: "",
        contaPagadora: {
            nome: "",
            contaCorrente: "",
            agencia: "",
            formaPagamento: "",
            tipoConta: ""
        },
        contaRecebedora: {
            nome: "",
            contaCorrente: "",
            agencia: ""
        },
        dadosVinculo: {
            descricao: "",
            gatilhos: [],
            dataEncerramento: "", 
            idVinculo: "",
            limiteVinculo: "",    
            limiteTransacao: ""
        },
        creationDateTime: "",
        creditorCpfCnpj: "",
        loggedUserDocIdentification: ""
    },
    payload: {
        autenticacaoEletronica: "",
        biometriaFacial: "",
        hashDispositivo: "",
        hashTransacao: "",
        teclas: [],
        tokenSMS: "",
        unitoken: ""
    },
    iniciadora: {
        urlRedirecionamento: "",
        urlLogoIniciadora: "",
        pendente: false
    }
};

export const AutomaticoRequest = React.createContext(dadosPagamentoAutomatico);

export const PagamentoAutomaticoProvider = ({ children }: Props) => {

    return (
        <AutomaticoRequest.Provider value={dadosPagamentoAutomatico}>
            {children}
        </AutomaticoRequest.Provider>
    );
}