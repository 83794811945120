import api from './api';
import { PayloadPagamentoAutomatico } from '../interfaces/automatico';

export const pagamentoAutomatico = {
    /**
     * @param status
     * It must be AUTORIZADO | CANCELADO
     */
    atualizaPagamentoAutomatico: (recurringConsentId: string, status: string, payload: PayloadPagamentoAutomatico) => {
        return api.put(
            `/openfinance/automatico/v1/consentimentos-autorizacoes/${recurringConsentId}/status/${status}`,
            payload
        );
    },
};